require('./bootstrap');

import Alpine from 'alpinejs';
import ApexCharts from 'apexcharts'
import {tns} from "tiny-slider";


window.ApexCharts = ApexCharts;
window.tns = tns;


jQuery(document).ready(function($) {
    $('.collapsable_card .trigger').on('click', function() {
        const card = $(this).closest('.collapsable_card');
        const slot = $(card).find('.slot');
        const chevron = $(card).find('.collapse_arrow');

        $(slot).toggleClass('hidden');
        if($(slot).hasClass('hidden')) {
            $(chevron).removeClass('fa-chevron-up');
            $(chevron).addClass('fa-chevron-down');
        } else {
            $(chevron).addClass('fa-chevron-up');
            $(chevron).removeClass('fa-chevron-down');
        }
    });

    $('.mobile-menu-toggler').on('click', function (){
        console.log('click');
        if($('.mobile-menu').hasClass('mobile-menu--active')){
            console.log('has class');
            $('.mobile-menu').removeClass('mobile-menu--active');
        } else {
            console.log('!has classs');
            $('.mobile-menu').addClass('mobile-menu--active');
        }
    });
});

